
<!-- template for the modal component -->
<template>
  <transition name="modal">
    <div class="modal-mask" role="dialog">
      <div class="modal-dialog" :class="modalClass" role="document">
        <div class="modal-content" v-click-outside="onClickOutside">

          <div class="modal-header pb-0">
            <slot name="header">
              <h4 class="modal-title">Default title</h4>
            </slot>
            <button type="button" class="close" @click="$emit('close')"><img class="close-modal" src="https://asurcloudrunprod-16905.kxcdn.com/public-assets/close-pink" alt=""></button>
          </div>

          <div class="modal-body pt-0">
            <slot name="body">
              Default body
            </slot>
          </div>

          <div class="modal-footer">
            <slot name="footer">
              <button type="button" class="btn btn-secondary" @click="$emit('ok')">OK</button>
              <button type="button" class="btn btn-default" @click="$emit('close')"><i class="fal fa-times"></i> Close</button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script nonce>
import vClickOutside from 'v-click-outside'

  export default {
    props: {
      modalClass: {
        type: String
      }
    },
    directives: {
      clickOutside: vClickOutside.directive
    },
    beforeMount: function() {
      document.body.style.overflow='hidden';
    },
    beforeDestroy: function() {
      document.body.style.overflow='';
    },
    methods: {
      onClickOutside (event) {
        this.$emit("close");
      }
    }
  }
</script>

<style lang="scss" scoped>  
.modal-content {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 0 !important;
}
.modal-dialog {
  border-radius: 0 !important;
}
.form-control {
  background: #F5F5F5 !important;
  border: solid 1px #E8E8E8;
}
/* Modal hader and mask */

.modal-header {
    color: #242424;
}

.modal-mask {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1040;
    background-color: rgba(0, 0, 0, .5);
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
    overflow-y: auto;
}


/* The following styles are auto-applied to elements with transition="modal"
* when their visibility is toggled by Vue.js.  */

.modal-enter-active,
.modal-leave-active {
    transition: opacity .3s;
}

.modal-enter,
.modal-leave-to {
    opacity: 0;
}
.form_header p{
  font-weight: 700 !important;
  font-size: .9em !important;
}
.close-modal{
  width: 14px;
  height: 14px;
}
@media (min-width: 500px) {
    .modal-xl {
        width: 80%;
    }
    .modal-xxl {
        width: 98%;
    }
}

</style>
