
<transition name="modal">
  <div class="modal-mask" role="dialog">
    <div class="modal-dialog" :class="modalClass" role="document">
      <div class="modal-content" v-click-outside="onClickOutside">

        <div class="modal-header pb-0">
          <slot name="header">
            <h4 class="modal-title">Default title</h4>
          </slot>
          <button type="button" class="close" @click="$emit('close')"><img class="close-modal" src="https://asurcloudrunprod-16905.kxcdn.com/public-assets/close-pink" alt=""></button>
        </div>

        <div class="modal-body pt-0">
          <slot name="body">
            Default body
          </slot>
        </div>

        <div class="modal-footer">
          <slot name="footer">
            <button type="button" class="btn btn-secondary" @click="$emit('ok')">OK</button>
            <button type="button" class="btn btn-default" @click="$emit('close')"><i class="fal fa-times"></i> Close</button>
          </slot>
        </div>
      </div>
    </div>
  </div>
</transition>
